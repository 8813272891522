import { useCallback } from 'react'
import Calendar from './Calendar'

function Single({
  selectedDate,
  onSelectedDatesChange,
  minDate,
  rangeFromMin = false, // display a range from the minimum date
  className,
  onDisplayedDaysChange,
  contextualData,
}) {
  const handleDateSelected = useCallback(
    ({ selectable, date }) => {
      if (!selectable) {
        return
      }
      onSelectedDatesChange(date ? [date] : [])
    },
    [onSelectedDatesChange]
  )

  const displayRange = minDate && rangeFromMin

  return (
    <Calendar
      selected={
        displayRange
          ? selectedDate
            ? [minDate, selectedDate]
            : [minDate]
          : selectedDate
      }
      onDateSelected={handleDateSelected}
      className={className}
      minDate={minDate}
      onDisplayedDaysChange={onDisplayedDaysChange}
      contextualData={contextualData?.['*'] || {}}
      step={displayRange ? 1 : 0}
    />
  )
}

export default Single
