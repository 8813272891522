import api from '@/services/api'
import {
  addDays,
  subDays,
  startOfMonth,
  endOfMonth,
  addMonths,
  subMonths,
  compareAsc,
  max,
  min,
} from 'date-fns'

const BUCKET_MAX_NUMBER_OF_MONTHS = 2
const CALENDAR_MAX_NUMBER_OF_MONTHS = 6

function entryKey(
  origin,
  destination,
  journeyType,
  partnership,
  departureDate
) {
  return (
    ':' +
    origin +
    ':' +
    destination +
    ':' +
    journeyType +
    ':' +
    partnership +
    ':' +
    (departureDate ? departureDate + ':' : '')
  )
}

function pad(i) {
  return (i > 9 ? '' : '0') + i
}

function removeTime(date) {
  return new Date(date.toDateString())
}

function today() {
  return removeTime(new Date())
}

function maxEnDate() {
  return addMonths(today(), CALENDAR_MAX_NUMBER_OF_MONTHS)
}

export function formatKeyFromDate(date) {
  const comp = getDateKeyComponents(date)
  return formatKeyFromComponents(...comp)
}

export function getDateKeyComponents(date) {
  return [date.getFullYear(), date.getMonth() + 1, date.getDate()]
}

export function formatKeyFromComponents(year, month, date) {
  return year + '-' + pad(month) + '-' + pad(date)
}

function priceScoreToColor(priceScore) {
  if (typeof priceScore == 'number') {
    if (priceScore >= 55) {
      return 'green'
    } else {
      return 'red'
    }
  }
  return null
}

/**
 * Returns the first day of the nth previous month
 */
export function prevMonth(date, n = 1) {
  return removeTime(startOfMonth(subMonths(date, n)))
}

/**
 * Returns the last day of the nth next month
 */
export function nextMonth(date, n = 1) {
  return removeTime(endOfMonth(addMonths(date, n)))
}

export function nextDay(date, n = 1) {
  return removeTime(addDays(date, n))
}

export function previousDay(date, n = 1) {
  return removeTime(subDays(date, n))
}

/**
 * A time bucket element, linked with the next element
 */
class TimeBucket {
  /**
   * Creates a new bucket with no sibling
   * @param {Date} start
   * @param {Date} end
   */
  constructor(start, end) {
    this.next = null
    this.start = start
    this.end = end
    this.attempt = 0
    this.result = null
    this.request = null
  }

  /**
   * Compare this bucket with a single date
   * Returns
   *  - 0 the given date is included into the bucket
   *  - 1 the given date is after this bucket
   *  - -1 the given date is before this bucket
   *
   * @param {Date} date
   */
  compareWithDate(date) {
    if (compareAsc(this.start, date) > 0) {
      // the start date is after the given date
      return -1
    }

    if (compareAsc(date, this.end) > 0) {
      // the given date is after the end date
      return 1
    }

    return 0
  }

  nextDay() {
    return nextDay(this.end, 1)
  }

  previousDay() {
    return previousDay(this.start, 1)
  }

  /**
   * Get the result of this
   * @param {Function} hit
   */
  async get(hit) {
    // execute the query
    if (!this.result && !this.request) {
      this.attempt++
      this.request = hit(this.start, this.end)
    }

    if (!this.result && this.request) {
      /*
        Returned results are like
        {
          "data": [{
            "date": "YYYY-MM-DD", # si roundtrip et departure_date set, correspond à la date du retour
            "available_offers": <0|10>, # pour l'instant, pourra évoluer avec des données plus précises plus tard (pour l'instant 10 == il y a des offres)
            "price_score": [0..100] # plus le score est élevé, meilleur est le prix
          }]
        }
      */
      try {
        this.result =
          (await this.request)?.data
            ?.filter((d) => d.date !== null)
            .map((d) => [
              formatKeyFromDate(new Date(d.date)),
              {
                disabled:
                  d.available_offers !== undefined && d.available_offers <= 0,
                color: priceScoreToColor(d.price_score),
              },
            ]) || []
      } catch (e) {
        if (e?.status !== 404) {
          console.warn('Unable to fetch contextual calendar data', e)
          // clear to retry later
          this.result = null
          this.request = null
        }
      }
    }

    return this.result || []
  }
}

/**
 * Creates a linked list of time buckets with a max duration of MAX_NUMBER_OF_MONTH
 *
 * @param {Date} from
 * @param {Date} to
 * @returns {TimeBucket[]}
 */
function createTimeBuckets(from, to) {
  const buckets = []

  const newBucket = (f, t) => {
    const b = new TimeBucket(f, t)
    if (buckets.length > 0) {
      buckets[buckets.length - 1].next = b
    }
    buckets.push(b)
  }

  let start = from
  let end = nextMonth(from, BUCKET_MAX_NUMBER_OF_MONTHS)

  // while we're not after the given end boundary
  while (compareAsc(end, to) < 0) {
    // we add a TWO month bucket
    newBucket(start, end)
    start = addDays(end, 1)
    end = nextMonth(start, BUCKET_MAX_NUMBER_OF_MONTHS)
  }

  // we add the last bucket
  newBucket(start, to)

  return buckets
}

/**
 * A cache entry is for an origin/destination and a journey type.
 * Keep a cache for each from/to date
 */
class CacheEntry {
  constructor(origin, destination, journeyType, partnership, departureDate) {
    this.origin = origin
    this.destination = destination
    this.journeyType = journeyType
    this.partnership = partnership
    this.departureDate = departureDate
    this.days = new Map()
    this.buckets = null
  }

  key() {
    return entryKey(
      this.origin,
      this.destination,
      this.journeyType,
      this.partnership,
      this.departureDate
    )
  }

  forEachBucket(callback) {
    let cursor = this.buckets
    while (cursor !== null) {
      callback(cursor)
      cursor = cursor.next
    }
  }

  hit(start, end) {
    // ensure once again we're not hiting before today
    start = max([start, addDays(today(), 1)])

    // format keys
    const startKey = formatKeyFromDate(start)
    const endKey = formatKeyFromDate(end)

    // check if the dates are different, otherwise don't event hit, returns an empty result
    if (startKey === endKey) {
      return Promise.resolve(null)
    }

    /*
      Query string params :
       -  origin : code IATA origine
       -  destination : code IATA destination
       -  journey_type : <oneway|roundtrip> (aller-simple ou aller-retour)
       -  start : premier jour à partir duquel on veut des infos (donc premier jour du (premier) mois affiché)
       -  end : dernier jour pour lequel on veut des infos (donc dernier jour du (dernier) mois affiché)
       -  departure_date : à set dans le cas d'un aller-retour, une fois la date de départ sélectionnée par l'utilisateur
    */
    return api.calendar.getDays(
      this.origin,
      this.destination,
      this.journeyType,
      startKey,
      endKey,
      this.partnership,
      this.departureDate
    )
  }

  /**
   * Compute time buckets for the given period
   * The buckets will never be longer than MAX_NUMBER_OF_MONTH
   *
   * @param {Date} start
   * @param {Date} end
   * @returns {TimeBucket[]}
   */
  getTimeBuckets(start, end) {
    // specific case of NO buckets
    // creates the first one
    if (this.buckets === null) {
      const b = createTimeBuckets(start, end)
      this.buckets = b[0]
      return b
    }

    let bucketsToReturn = []
    let cursor = this.buckets
    let prev = null

    const advance = () => {
      prev = cursor
      cursor = cursor.next
    }

    const insertBuckets = (b) => {
      b[b.length - 1].next = cursor
      if (prev === null) {
        this.buckets = b[0]
      } else {
        prev.next = b[0]
      }
    }

    while (cursor !== null) {
      const cmpS = cursor.compareWithDate(start)
      const cmpE = cursor.compareWithDate(end)

      // 1 - we are before this bucket
      if (cmpE < 0) {
        // we creates an entire bucket
        const b = createTimeBuckets(start, end)
        bucketsToReturn = bucketsToReturn.concat(b)

        // link the bucket
        insertBuckets(b)

        // we break here, we have all we want
        break
      }

      // 2 - we are after this bucket
      if (cmpS > 0) {
        // this is not the last bucket
        // let's handle with the next one
        if (cursor.next !== null) {
          advance()
          continue
        }

        const b = createTimeBuckets(
          start,
          min([maxEnDate(), nextMonth(start, BUCKET_MAX_NUMBER_OF_MONTHS)])
        )
        bucketsToReturn = bucketsToReturn.concat(b)
        cursor.next = b[0]
        break
      }

      // 3 - the start is before this bucket
      if (cmpS < 0) {
        // we cut the bucket here
        const b = createTimeBuckets(start, cursor.previousDay())
        bucketsToReturn = bucketsToReturn.concat(b)
        insertBuckets(b)

        // and we'll let the next iteration decide for the remaining
        start = cursor.start
        continue
      }

      // 4 - we are completly included into this bucket
      if (cmpE === 0) {
        bucketsToReturn.push(cursor)
        break
      }

      // 5 - the start is inside the bucket and the end is after
      // includes the bucket and push the start to the end
      // we don't advance to let the next iteration handle this
      bucketsToReturn.push(cursor)
      start = cursor.nextDay()
    }

    return bucketsToReturn
  }

  async get(start, end) {
    // first we remove the time component, if any
    start = removeTime(start)
    end = removeTime(end)

    // start can't be before today
    start = max([start, addDays(today(), 1)])
    // end can't be after the MAX
    end = min([end, maxEnDate()])

    // ensure the bucket is valid and not empty (the backend doens't support an empty bucket)
    if (compareAsc(start, end) >= 0) {
      return {}
    }

    const buckets = this.getTimeBuckets(start, end).map((b) =>
      b.get((s, e) => this.hit(s, e))
    )

    // for each bucket we get the value
    const results = []
    for (const r of buckets) {
      const d = await r
      results.push(d)
    }

    return Object.fromEntries(results.flatMap((e) => e)) || {}
  }

  describe() {
    const list = []
    this.forEachBucket((b) =>
      list.push({
        from: formatKeyFromDate(b.start),
        to: formatKeyFromDate(b.end),
      })
    )

    return {
      origin: this.origin,
      destination: this.destination,
      journeyType: this.journeyType,
      partnersip: this.partnership,
      buckets: list,
    }
  }
}

/**
 * The cache of requets
 * The key of cached element is a
 */
const cache = new Map()

/**
 * Retrive an entry with the given parameters
 *
 * @param {string} origin
 * @param {string} destination
 * @param {string} journeyType
 * @param {string} partnership
 * @param {Date} departureDate
 * @returns {CacheEntry}
 */
function getEntry(
  origin,
  destination,
  journeyType,
  partnership = null,
  departureDate = null
) {
  const k = entryKey(
    origin,
    destination,
    journeyType,
    partnership,
    departureDate
  )
  if (cache.has(k)) {
    return cache.get(k)
  }

  const e = new CacheEntry(
    origin,
    destination,
    journeyType,
    partnership,
    departureDate
  )
  cache.set(k, e)
  return e
}

/**
 * Returns contextual information about days for the given destination
 *
 * Contextul data exemple
 * ```
 * {
 *   "20230112": {
 *     disabled: true
 *   },
 *   "20230204": {
 *     disabled: false
 *     color: 'green'
 *   }
 * }
 * ```
 *
 * @param {string} origin
 * @param {string} destination
 * @param {string} journeyType
 * @param {string} partnership
 * @param {Date} departureDate
 * @param {Date} start
 * @param {Date} end
 *
 * @returns {Object} containing informations about days
 */
export async function getDays(
  origin,
  destination,
  journeyType,
  partnership = null,
  departureDate = null,
  start,
  end
) {
  // adapt the journey type
  // multi -> is like several one ways
  if (journeyType === 'multi') {
    journeyType = 'oneway'
  }

  if (!['oneway', 'roundtrip'].includes(journeyType)) {
    console.warn('Invalid journey type', journeyType)
    return {}
  }

  // we hit the two entries, with and without the departure date
  const days = getEntry(
    origin,
    destination,
    journeyType,
    partnership,
    null
  ).get(start, end)

  // handle a departure date, only if before the end
  if (
    departureDate != null &&
    journeyType == 'roundtrip' &&
    compareAsc(departureDate, end) <= 0
  ) {
    const d = formatKeyFromDate(departureDate)
    // adjust the start with the departure date
    const s = compareAsc(departureDate, start) > 0 ? departureDate : start

    const daysForDD = getEntry(
      origin,
      destination,
      journeyType,
      partnership,
      d
    ).get(s, end)

    return {
      '*': await days,
      [d]: await daysForDD,
    }
  }

  return { '*': await days }
}
