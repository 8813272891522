import { useEffect, useRef, useState, useCallback, useMemo, memo } from 'react'
import { useRouter } from 'next/router'
import {
  isBefore,
  isAfter,
  startOfDay,
  getMonth,
  getYear,
  addYears,
  addDays,
  differenceInDays,
} from 'date-fns'
import { useDayzed } from 'dayzed'
import useTranslation from '@/hooks/translation'
import useBreakpoints from '@/hooks/breakpoints'
import Chevron from '@/assets/svg/chevron.svg'
import Button from '@/components/ui/button'
import { formatKeyFromDate } from '@/logic/ContextualCalendarCache'
import clsx from 'clsx'
import useAnalytics from '@/hooks/analytics'

const monthKeys = [
  'january',
  'february',
  'march',
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december',
]
const weekdayKeys = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
]

function pad(i) {
  return (i > 9 ? '' : '0') + i
}

// Return tomorrow
// but if it's after 2pm UTC, return the day after tomorrow
function getMinDate() {
  const now = new Date()

  if (now.getUTCHours() >= 15) {
    return addDays(startOfDay(now), 2)
  }
  return addDays(startOfDay(now), 1)
}

const Day = memo(function WrappedDay({
  time,
  isDateSelected,
  isDateSelectable,
  dayWidth,
  step,
  isFirstOfWeek,
  isLastOfWeek,
  isWithinRange,
  isFirstOfRange,
  isLastOfRange,
  setRange,
  ctx,
  onClick,
  // TODO optimize those date props
  ...dateProps
}) {
  const date = new Date(time)
  const { track } = useAnalytics()
  return (
    <div className="relative flex flex-col items-center justify-items-center">
      <Button
        className={`${
          isFirstOfRange && !isLastOfRange
            ? 'bg-primary-20 rounded-l-full'
            : isLastOfRange && !isFirstOfRange
            ? 'bg-primary-20 rounded-r-full'
            : isWithinRange && isFirstOfWeek && isLastOfWeek
            ? 'bg-primary-20 rounded-l rounded-r'
            : isWithinRange && isFirstOfWeek
            ? 'bg-primary-20 rounded-l'
            : isWithinRange && isLastOfWeek
            ? 'bg-primary-20 rounded-r'
            : isWithinRange
            ? 'bg-primary-20'
            : ''
        }`}
        onClick={() => {
          if (ctx?.color === 'green') {
            track('prebooking_calendar_greendot_clicked')
          }
          onClick({
            selectable: isDateSelectable,
            selected: isDateSelected,
            date,
            ctx,
          })
        }}
        {...dateProps}
        unstyled
      >
        <span
          className={`${
            isDateSelected
              ? 'bg-primary text-white rounded-full'
              : !isDateSelectable && !ctx?.disabled
              ? 'opacity-50 cursor-not-allowed'
              : !isDateSelectable && ctx?.disabled
              ? 'opacity-50 cursor-not-allowed line-through'
              : ''
          } inline-block`}
        >
          <span
            style={{ width: dayWidth, height: dayWidth }}
            onMouseOver={() => {
              if (step > 0) {
                //setOverDate(date)
                setRange((previous) => {
                  const f = previous[0]
                  if (isAfter(date, f)) {
                    return [f, date]
                  } else if (previous.length == 1) {
                    return previous
                  } else {
                    return [f]
                  }
                })
              }
            }}
            className={clsx(
              'border-2 border-transparent rounded-full inline-flex items-center justify-center text-17',
              'hover:border-primary'
            )}
          >
            {pad(date.getDate())}
          </span>
        </span>
      </Button>
      {ctx?.color && ctx.color == 'green' && (
        <span className="absolute block bg-green w-[7px] h-[7px] rounded-full bottom-[4px]"></span>
      )}
    </div>
  )
})

/*
Contextul data exemple
{
  "2023-01-12": {
    disabled: true
  },
  "2023-02-04": {
    disabled: false
    color: 'green'
  }
}
*/
function Calendar({
  selected,
  onDateSelected,
  minDate,
  className,
  onDisplayedDaysChange,
  contextualData: context,
  step = 0,
}) {
  const router = useRouter()
  const calendarRefs = useRef([])
  const containerRef = useRef()

  const [originalWidth, setWidth] = useState(660) // 660 is the default desktop size
  const { isTablet, isDesktop } = useBreakpoints()

  const width = isTablet ? originalWidth : originalWidth / 2
  const dayWidth = width / 7

  // We need to store this in state to only change it on open.
  // Otherwise Dayzed moves the calendar when selecting a date.
  const [openingDate, setOpeningDate] = useState()

  const firstDayOfWeek = router.locale === 'en' ? 0 : 1

  // the displayed range
  const [range, setRange] = useState([])

  const dayzedData = useDayzed({
    date: isTablet ? undefined : openingDate,
    selected: selected,
    onDateSelected,
    firstDayOfWeek,
    minDate: minDate ? startOfDay(minDate) : getMinDate(),
    maxDate: startOfDay(addYears(new Date(), 1)),
    monthsToDisplay: isTablet ? 13 : 2,
  })

  const { calendars, getDateProps, getBackProps, getForwardProps } = dayzedData
  const { t } = useTranslation()
  const hasGreenDays = useMemo(() => {
    return (
      Object.values(context || {}).filter(({ color }) => color === 'green')
        .length > 0
    )
  }, [context])

  const hasGrayDays = useMemo(() => {
    return (
      Object.values(context || {}).filter(({ disabled }) => disabled === true)
        .length > 0
    )
  }, [context])

  const initialRange =
    selected == null ? [] : Array.isArray(selected) ? selected : [selected]
  const rangeOrInitial = Array.isArray(range) ? range : initialRange

  // compute the initial range
  useEffect(() => {
    setRange(initialRange)
  }, [selected, setRange])

  useEffect(() => {
    if (calendars && onDisplayedDaysChange) {
      onDisplayedDaysChange(calendars.slice(0, 2))
    }
  }, [calendars[0]?.month, calendars?.length])

  useEffect(() => {
    if (
      isTablet === true &&
      ((Array.isArray(selected) && selected.length) || selected instanceof Date)
    ) {
      const month = Array.isArray(selected)
        ? getMonth(selected[0])
        : getMonth(selected)
      const year = Array.isArray(selected)
        ? getYear(selected[0])
        : getYear(selected)

      const index = calendars.findIndex(
        (calendar) => calendar.month === month && calendar.year === year
      )

      const calendarEl = calendarRefs.current[index]

      if (calendarEl && containerRef.current?.firstElementChild) {
        // Need the offset because of the fixed weekday header
        containerRef.current.firstElementChild.scroll({
          top: calendarEl.offsetTop - 50,
        })
      }
    }
  }, [isTablet])

  useEffect(() => {
    if (isTablet === false) {
      setOpeningDate(
        Array.isArray(selected) && selected.length
          ? selected[0]
          : selected instanceof Date
          ? selected
          : undefined
      )
    }
  }, [isTablet])

  const calculateWidth = useCallback(() => {
    if (containerRef.current) {
      const dimensions = containerRef.current.getBoundingClientRect()

      if (dimensions.width) {
        // 40 = 2 x side margin of 20px
        setWidth(dimensions.width > 1000 ? 960 : dimensions.width - 40)
      }
    }
  }, [containerRef, setWidth])

  useEffect(() => {
    // avoid recomputing the width on desktop, to avoid resize and popup issues
    if (!isDesktop) {
      calculateWidth()
    }
    if (window) {
      window.addEventListener('resize', calculateWidth)
      return () => {
        window.removeEventListener('resize', calculateWidth)
      }
    }
  }, [containerRef, setWidth, isDesktop])

  if (calendars.length) {
    return (
      <div
        ref={containerRef}
        className={clsx(
          'absolute transform md:relative overflow-hidden md:overflow-y-scroll top-0 md:top-auto right-0 md:right-auto bottom-0 md:bottom-auto left-0 bg-white md:mt-5 md:rounded-5 md:shadow-double md:w-700 z-10',
          className
        )}
      >
        <div className="fixed md:static top-0 bottom-0 right-0 left-0 overflow-y-scroll md:overflow-auto">
          <div className="mx-auto pb-60 md:pb-20 md:flex">
            {calendars.map((calendar, index) => (
              <div key={`${calendar.month}${calendar.year}`} className="w-full">
                {!isTablet || index === 0 ? (
                  <div className="fixed md:relative w-full z-10 bg-white left-1/2 transform -translate-x-1/2 py-12 border-b border-dark border-opacity-5 text-center">
                    {Array(7)
                      .fill()
                      .map((_, index) => {
                        const weekdayIndex = (index + firstDayOfWeek) % 7
                        return (
                          <div
                            key={`${calendar.month}${calendar.year}${weekdayIndex}`}
                            style={{ width: dayWidth }}
                            className="inline-block text-12 opacity-50 text-center"
                          >
                            {t(
                              `components.datePicker.weekday.${weekdayKeys[weekdayIndex]}`
                            )}
                          </div>
                        )
                      })}
                  </div>
                ) : null}
                <div
                  ref={(el) => {
                    calendarRefs.current[index] = el
                  }}
                  style={{ maxWidth: width }}
                  className={`${index === 0 ? 'pt-45 md:pt-0' : ''} mx-auto`}
                >
                  <div className="mx-10 mt-30 md:mt-25 mb-8 md:mb-15 relative">
                    <div className="font-bold text-22 md:text-base md:text-center">
                      {t(
                        `components.datePicker.months.${
                          monthKeys[calendar.month]
                        }`
                      )}{' '}
                      {calendar.year}
                    </div>
                    {index === 0 ? (
                      <Button
                        {...getBackProps({ calendars })}
                        unstyled
                        className="rounded-full p-8 border border-dark disabled:border-dark-20 text-dark disabled:text-dark-20 mr-10 hidden md:block absolute top-1/2 -translate-y-1/2 transform"
                      >
                        <Chevron className="w-12 transform rotate-90" />
                      </Button>
                    ) : null}
                    {index === 1 ? (
                      <Button
                        {...getForwardProps({ calendars })}
                        unstyled
                        className="rounded-full p-8 border border-dark disabled:border-dark-20 text-dark disabled:text-dark-20 hidden md:block absolute top-1/2 right-0 -translate-y-1/2 transform"
                      >
                        <Chevron className="w-12 transform -rotate-90" />
                      </Button>
                    ) : null}
                  </div>
                  {calendar.weeks.map((week, weekIndex) => (
                    <div
                      className="mt-2 flex justify-around"
                      key={`${calendar.month}${calendar.year}${weekIndex}`}
                    >
                      {week.map((dateObj, index) => {
                        let key = `${calendar.month}${calendar.year}${weekIndex}${index}`

                        if (!dateObj) {
                          return (
                            <span
                              key={key}
                              style={{ width: dayWidth }}
                              className="inline-block"
                            />
                          )
                        }

                        // get the context for this date
                        const ctx = context?.[formatKeyFromDate(dateObj.date)]
                        dateObj.selectable &= ctx?.disabled !== true

                        const {
                          date,
                          selected: isDateSelected,
                          selectable: isDateSelectable,
                        } = dateObj

                        const isFirstOfWeek =
                          index === 0 ||
                          date.getDate() === calendar.firstDayOfMonth.getDate()
                        const isLastOfWeek =
                          index === 6 ||
                          date.getDate() === calendar.lastDayOfMonth.getDate()

                        const isWithinRange =
                          range.length === 2 &&
                          isAfter(date, range[0]) &&
                          isBefore(date, range[1])
                        const isFirstOfRange =
                          range.length === 2 &&
                          date.getTime() == range[0].getTime()
                        const isLastOfRange =
                          range.length === 2 &&
                          date.getTime() == range[1].getTime()

                        return (
                          <Day
                            key={key}
                            isWithinRange={isWithinRange}
                            isFirstOfRange={isFirstOfRange}
                            isLastOfRange={isLastOfRange}
                            time={date.getTime()}
                            isDateSelectable={isDateSelectable}
                            isDateSelected={isDateSelected}
                            dayWidth={dayWidth}
                            setRange={setRange}
                            step={step}
                            isFirstOfWeek={isFirstOfWeek}
                            isLastOfWeek={isLastOfWeek}
                            ctx={ctx}
                            {...getDateProps({ dateObj })}
                            onClick={onDateSelected}
                          />
                        )
                      })}
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
          <div className="fixed bottom-0 bg-white w-full md:static flex justify-between px-20 text-12 text-dark-60 text-dark-4 border-t border-dark-5 items-center">
            {(hasGreenDays || hasGrayDays) && (
              <div className="inline-flex items-center gap-10 align-middle py-8">
                {hasGreenDays && (
                  <>
                    <span className="bg-green w-10 h-10 rounded-full"></span>
                    <span>{t('components.datePicker.cheaper')}</span>
                  </>
                )}
                {hasGrayDays && (
                  <>
                    <svg
                      width="10"
                      height="10"
                      viewBox="0 0 10 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M9.89998 6H0.100018C0.563288 8.28224 2.58104 10 5 10C7.41896 10 9.43671 8.28224 9.89998 6ZM10 5C10 2.23858 7.76142 0 5 0C2.23858 0 0 2.23858 0 5H10Z"
                        fill="#0F2041"
                        fillOpacity="0.2"
                      />
                    </svg>
                    <span>{t('components.datePicker.unavailable')}</span>
                  </>
                )}
              </div>
            )}
            {/* display the footer with the intial range to avoid resizing the calendar */}
            {Array.isArray(rangeOrInitial) && rangeOrInitial.length === 2 ? (
              <div className="text-right hidden md:block py-8">
                {t('components.datePicker.days', {
                  count:
                    differenceInDays(rangeOrInitial[1], rangeOrInitial[0]) + 1,
                })}
              </div>
            ) : step > 0 ? (
              <div className="text-right hidden md:block py-8">
                {t('components.datePicker.chooseReturnDate')}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    )
  }
  return null
}

export default Calendar
